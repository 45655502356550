export const arrShadesData = {
    "tabs": [
{
"id": "black",
"label": "Black"
},
{
"id": "brown",
"label": "Brown"
},
{
"id": "blonde",
"label": "Blonde"
},
{
"id": "red",
"label": "Red"
  },
{
 "id": "trend",
 "label": "Trend"
 }
],
"color_palettes": [
{
"id": "black",
"shades": [
{
  "rgb": [0.03, 0.019, 0.011],
  "image_src": "./shades/black_1.jpg",
  "name": "10-Black"
},
{
  "rgb": [0.101, 0.094, 0.105],
  "image_src": "./shades/black_2.jpg",
  "name": "1.0-Black"
},
{
  "rgb": [0.2, 0.164, 0.145],
  "image_src": "./shades/black_3.jpg",
  "name": "20-Soft Black"
},
{
  "rgb": [0.149, 0.121, 0.098],
  "image_src": "./shades/black_4.jpg",
  "name": "200-Black Sesame"
},
{
  "rgb": [0.047, 0.031, 0.031],
  "image_src": "./shades/black_5.jpg",
  "name": "2.11 - Platinum Black"
},
{
  "rgb": [0.121, 0.149, 0.219],
  "image_src": "./shades/black_6.jpg",
  "name": "BL21 - Reflective Blue Black"
},
{
  "rgb": [0.152, 0.176, 0.223],
  "image_src": "./shades/black_7.jpg",
  "name": "22 - Intense Blue Black"
},
{
  "rgb": [0.070, 0.070, 0.078],
  "image_src": "./shades/black_8.jpg",
  "name": "BL11 - Reflective Jet Blue Black"
}
]
},
{
  "id": "brown",
  "shades": [
{
  "rgb": [0.255, 0.180, 0.145],
  "image_src": "./shades/brown_1.jpg",
  "name": "30 - Darkest Brown"
},
{
  "rgb":  [0.224, 0.200, 0.200],
  "image_src": "./shades/brown_2.jpg",
  "name": "3.0 - Darkest Brown"
},
{
  "rgb": [0.376, 0.322, 0.318],
  "image_src": "./shades/brown_3.jpg",
  "name": "3.11 - Darkest Platinum Brown"
},
{
  "rgb": [0.357, 0.298, 0.275],
  "image_src": "./shades/brown_4.jpg",
  "name": "33 - Darkest Golden Brown"
},
{
  "rgb": [0.192, 0.125, 0.094],
  "image_src": "./shades/brown_5.jpg",
  "name": "40 - Dark Brown"
},
{
  "rgb": [0.251, 0.157, 0.133],
  "image_src": "./shades/brown_6.jpg",
  "name": "400 - Sweet Pecan"
},
{
  "rgb": [0.294, 0.212, 0.184],
  "image_src": "./shades/brown_7.jpg",
  "name": "4.0 - Dark Brown"
},
{
  "rgb": [0.247, 0.160, 0.113],
  "image_src": "./shades/brown_8.jpg",
  "name": "413 - Bronze Brown"
},
{
  "rgb": [0.306, 0.200, 0.165],
  "image_src": "./shades/brown_9.jpg",
  "name": "415 - Soft Mahogany Dark Brown"
},
{
  "rgb": [0.172, 0.098, 0.070],
  "image_src": "./shades/brown_10.jpg",
  "name": "43 - Dark Golden Brown"
},
{
  "rgb": [0.871, 0.553, 0.337],
  "image_src": "./shades/brown_11.jpg",
  "name": "63 - Light Golden Brown"
},
{
  "rgb": [0.569, 0.373, 0.188],
  "image_src": "./shades/brown_12.jpg",
  "name": "630 - Toffee Nut"
},
{
  "rgb": [0.596, 0.431, 0.224],
  "image_src": "./shades/brown_13.jpg",
  "name": "6.3 - Light Golden Brown"
},
{
  "rgb": [0.706, 0.506, 0.337],
  "image_src": "./shades/brown_14.jpg",
  "name": "B3 - Golden Brown"
},
{
  "rgb": [0.631, 0.408, 0.278],
  "image_src": "./shades/brown_15.jpg",
  "name": "B4 - Caramel Chocolate"
}
  ]
  },
  {
"id": "blonde",
"shades": [
{
  "rgb": [0.776, 0.561, 0.412],
  "image_src": "./shades/blonde_1.jpg",
  "name": "70 - Dark Natural Blonde"
},
{
  "rgb": [0.725, 0.557, 0.424],
  "image_src": "./shades/blonde_2.jpg",
  "name": "700 - Candied Cashew"
},
{
  "rgb": [0.588, 0.451, 0.341],
  "image_src": "./shades/blonde_3.jpg",
  "name": "7.0 - Dark Blonde"
},
{
  "rgb": [0.745, 0.573, 0.416],
  "image_src": "./shades/blonde_4.jpg",
  "name": "73 - Dark Golden Blonde"
  },
{
  "rgb": [0.871, 0.698, 0.545],
  "image_src": "./shades/blonde_5.jpg",
  "name": "80 - Medium Natural Blonde"
},
{
  "rgb": [0.929, 0.761, 0.600],
  "image_src": "./shades/blonde_6.jpg",
  "name": "800 - Almond Cookie"
},
{
  "rgb": [0.875, 0.729, 0.573],
  "image_src": "./shades/blonde_7.jpg",
  "name": "8.0 - Medium Blonde"
},
{
  "rgb": [0.592, 0.455, 0.349],
  "image_src": "./shades/blonde_8.jpg",
  "name": "811 - Medium Extra Ash Blonde"
},
{
  "rgb": [0.678, 0.529, 0.392],
  "image_src": "./shades/blonde_9.jpg",
  "name": "82 - Champagne Blonde"
},
{
  "rgb": [0.675, 0.498, 0.337],
  "image_src": "./shades/blonde_10.jpg",
  "name": "8.31 - Medium Golden Blonde"
},
{
  "rgb": [0.945, 0.863, 0.737],
  "image_src": "./shades/blonde_11.jpg",
  "name": "11.3 - Extra Light Sun Blonde"
},
{
  "rgb": [0.961, 0.890, 0.835],
  "image_src": "./shades/blonde_12.jpg",
  "name": "PL2 - Ultra Light Platinum"
}
]
},
{
  "id": "red",
"shades": [
{
  "rgb": [0.624, 0.361, 0.318],
  "image_src": "./shades/red_1.jpg",
  "name": "7.20 - Dark Rose Quartz"
},
{
  "rgb": [0.910, 0.518, 0.353],
  "image_src": "./shades/red_2.jpg",
  "name": "7.45 - Dark Fire Ruby"
},
{
  "rgb": [0.459, 0.267, 0.345],
  "image_src": "./shades/red_3.jpg",
  "name": "BR1 - Deepest Intense Burgundy"
},
{
  "rgb": [0.475, 0.251, 0.251],
  "image_src": "./shades/red_4.jpg",
  "name": "BR2 - Dark Intense Burgundy"
},
{
  "rgb": [0.851, 0.416, 0.435],
  "image_src": "./shades/red_5.jpg",
  "name": "BR3 - Intense Burgundy"
},
{
  "rgb": [0.725, 0.416, 0.298],
  "image_src": "./shades/red_6.jpg",
  "name": "R2 - Medium Intense Auburn"
},
{
  "rgb": [0.475, 0.243, 0.306],
  "image_src": "./shades/red_7.jpg",
  "name": "R3 - Light Intense Auburn"
},
{
  "rgb": [0.471, 0.231, 0.243],
  "image_src": "./shades/red_8.jpg",
  "name": "RZ4 - Scarlett Ronze"
}
]
},
{
  "id": "trend",
  "shades": [
{
  "rgb": [1, 0.737, 0.773],
  "image_src": "./shades/trend_1.jpg",
  "name": "9.20 - Smell the Roses"
},
{
  "rgb": [0.643, 0.627, 0.635],
  "image_src": "./shades/trend_2.jpg",
  "name": "10.11 - Lightest Silver Blonde"
},
{
  "rgb": [0.431, 0.255, 0.306],
  "image_src": "./shades/trend_3.jpg",
  "name": "5.12 - Medium Royal Amethyst"
},
{
  "rgb": [0.161, 0.063, 0.216],
  "image_src": "./shades/trend_4.jpg",
  "name": "5.21 - Grape Expectations"
},
{
  "rgb": [0.122, 0.290, 0.353],
  "image_src": "./shades/trend_5.jpg",
  "name": "6.17 - Out of the Blue"
},
{
  "rgb": [0.576, 0.208, 0.200],
  "image_src": "./shades/trend_6.jpg",
  "name": "6.60 - Where there is smoke"
},
{
  "rgb": [0.886, 0.529, 0.533],
  "image_src": "./shades/trend_7.jpg",
  "name": "7.26 - California Sunset"
},
{
  "rgb": [0.455, 0.502, 0.498],
  "image_src": "./shades/trend_8.jpg",
  "name": "8.10 - Head in the Clouds"
},
{
  "rgb": [0.757, 0.553, 0.710],
  "image_src": "./shades/trend_9.jpg",
  "name": "8.21 - Sweet Lavender Dreams"
},
{
  "rgb": [0.949, 0.753, 0.694],
  "image_src": "./shades/trend_10.jpg",
  "name": "8.22 - Medium Rose Gold"
}
  ]
  } 
] 
}